<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab href="#tab-1">ຍອດຂາຍລວມ</v-tab>
        <v-tab href="#tab-2">ຍອດຂາຍຕາມລົດຂົນສົ່ງ</v-tab>
        <v-tab href="#tab-3">ຍອດຂາຍຕາມສາຂາ</v-tab>
      </v-tabs>
      <!-- <hr /> -->

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <v-card-text>
              <Sale />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-2">
          <v-card flat>
            <v-card-text>
              <DeliverySale />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-3">
          <v-card flat>
            <v-card-text> <BranchSale /> </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Sale from "@views/Finance/tabbar/sale_tab";
import BranchSale from "@views/Finance/tabbar/sale_branch_tab";
import DeliverySale from "@views/Finance/tabbar/sale_delivery_tab";
import manage from "@/mixins/finance/index";
export default {
  mixins: [manage],
  components: {
    Sale,
    BranchSale,
    DeliverySale,
  },
  data() {
    return {
      tab: null,
    };
  },
  created() {
    if (this.$route.query.tab == "report-all") {
      this.tab = "tab-1";
    } else if (this.$route.query.tab == "report-driver-sale") {
      this.tab = "tab-2";
    } else if (this.$route.query.tab == "report-branch-sale") {
      this.tab = "tab-3";
    }
  },
  watch: {
    tab: function (value) {
      if (value == "tab-1") {
        this.$router
          .push({ name: "Finance", query: { tab: "report-all" } })
          .catch(() => {});
      } else if (value == "tab-2") {
        this.$router
          .push({ name: "Finance", query: { tab: "report-driver-sale" } })
          .catch(() => {});
      } else if (value == "tab-3") {
        this.$router
          .push({ name: "Finance", query: { tab: "report-branch-sale" } })
          .catch(() => {});
      }
    },
  },
};
</script>

<style>
</style>
