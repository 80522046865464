export default {
    data(){
        return {
          title:"Finance",
          finances:[],
          startDate:'',
          endDate:'',
        }
      },
      methods:{
        fetchData(){
          this.TableLoading =true;
            this.$axios.post('finance/report-invoices',
            {startDate: this.startDate,
             endDate: this.endDate}
          ).then(res => {
              setTimeout(() => {
                  this.finances = res.data.invoices;
                  this.TableLoading =false;
              }, 100);
          }).catch(() => {
            this.TableLoading =false;
            this.$store.commit("Toast_State",this.toast_error);
          });
        },
      },
      created(){
        this.fetchData();
      }
    }
